import { DeclarationPropertiesTypes } from "fs-pb-vue2//data/enums/declarationPropertiesTypes";
import { OperationRequestData } from "fs-pb-vue2//data/operationRequestData";
import i18n from "@/i18n";
import { RecoveryModel } from "fs-pb-vue2/models/auth/RecoveryModel";
import { dateFilter } from "fs-pb-vue2/filters/date.filter";
import { PhoneFormat, PhoneFormatDir } from "fs-pb-vue2/data/phoneFormat";
import { EventCode } from "fs-pb-vue2/data/enums/eventCode";

/** Модель регистрации меткома */
export class FinServRecoveryModel extends RecoveryModel {
  /**Номер карты */
  public Pan: string = "";

  public BirthDate: string = '';

  /**Правила заполнения номера карты */
  public PanRules: Array<Function> = [
    (p: any) => !!p || i18n.t("auth.errors.panEmpty"),
    (p: any) =>
      (p && /^\d{4}\s\d{4}\s\d{4}\s\d{4}$/.test(p)) ||
      i18n.t("auth.errors.panCheck")
  ];

  /**Правила заполнения номера карты */
  public BirthRules: Array<Function> = [
    (p: any) => !!p || i18n.t("auth.errors.birthDateEmpty"),
    (p: any) => !!this.check(p) || i18n.t("auth.errors.birthDateInCorrect")
  ];

  /**Маска номера карты */
  public PanMask: string = '#### #### #### ####';
  
  //public PhoneMask: string = '+###############';
  /**Номер телефона */
  public Phone: string = "";

  public PhoneRightValue: string = "";

  public SelectedFormat: PhoneFormat = new PhoneFormat();

  public Formats: PhoneFormat[] = [];

  public FormatsExists: boolean = false;
  
  constructor(formats: PhoneFormatDir | null = null) {
    super();

    if (formats.Operations?.RecoveryPassword) {
      
      formats.Operations.RecoveryPassword.Formats.forEach((f: string) => {
        if (formats.FormatDict[f])
          this.Formats.push(formats.FormatDict[f]);
      });

      if(this.Formats.length)
        this.FormatsExists = true;
      
      this.SelectedFormat = this.Formats[0];
    }
  }

  private check(date: string): boolean {
    if (!date)
      return false;
    let d: any = date.substring(0, date.indexOf('.'));
    let m: any = date.substring(date.indexOf('.')+1, date.indexOf('.') + 3);
    let y: any = date.substring(date.indexOf('.') + 4, date.length);
    y = +y, --m, d = +d
    const x = new Date(y, m, d);
    return x.getFullYear() === y && x.getMonth() === m && x.getDate() === d
  }

  /**
   * Создание запроса операции.
   */
  public createOperationRequest(): OperationRequestData {
    const ord = new OperationRequestData();
    ord.respondentCode = this.RespondentCode;
    ord.operationId = this.OperationId;
    ord.EventCode = EventCode.ResetPassword;

    if(this.FormatsExists) {
      this.Phone = `${this.SelectedFormat.DisplayCode}${this.PhoneRightValue}`
    }
    
    const date: string = dateFilter(this.BirthDate, 'date');

    ord.setProperty("login", this.Pan.replace(/\s/g, ""), DeclarationPropertiesTypes.String);
    ord.setProperty("phone", this.Phone.replace(/\s|\(|\)|\-/g, ""), DeclarationPropertiesTypes.String);
    ord.setProperty("passport", this.Passport, DeclarationPropertiesTypes.String);
    ord.setProperty("number", "", DeclarationPropertiesTypes.String);
    ord.setProperty("birthdate", date + ' 0:00:00', DeclarationPropertiesTypes.DateTime);

    return ord;
  }

  /**
* Заполнение операции данными из ответа запроса.
* @param ord Данные запроса.
* @param bAssign Признак assign.
*/
  public setOperationFromRequest(ord: OperationRequestData): void {
    this.SuccessMessage = i18n.t("auth.messages.successrecoverypassword", { login: this.Login }).toString();
  }
}
