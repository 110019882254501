import { CardPinModel } from "fs-pb-vue2/models/cardOperations/CardPinModel";
import { DeclarationPropertiesTypes } from "fs-pb-vue2/data/enums/declarationPropertiesTypes";
import { OperationRequestData } from "fs-pb-vue2/data/operationRequestData";
import { EventCode } from "fs-pb-vue2/data/enums/eventCode";

/** Модель ссмены пароля */
export class CardPinModelFS extends CardPinModel {

  public oldpin: string | null = null;

  public externalConfirmCode: string | null = null;

  public operationReferenceId: string | null = null;

  constructor(src: any) {
    super(src);
  }

  /**
   * Создание запроса операции.
   */
  public createOperationRequest(): OperationRequestData {
    const ord = new OperationRequestData();
    ord.respondentCode = this.RespondentCode;
    ord.operationId = this.OperationId;
    ord.EventCode = EventCode.Request;

    ord.setProperty("card", this.card.Data.ReferenceId, DeclarationPropertiesTypes.String);
    ord.setProperty("oldpin", this.oldpin, DeclarationPropertiesTypes.String);
    ord.setProperty("externalConfirmCode", this.externalConfirmCode, DeclarationPropertiesTypes.String);
    ord.setProperty("operationReferenceId", this.operationReferenceId, DeclarationPropertiesTypes.String);
    ord.setProperty("newpin", this.Pin, DeclarationPropertiesTypes.String);

    return ord;
  }

  /**
 * Заполнение операции данными из ответа запроса.
 * @param ord Данные запроса.
 * @param bAssign Признак assign.
 */
  public setOperationFromRequest(...args: any[]): void {
    if (args) {
      const ord: any = args[0];
      if (ord && ord.keys && ord.keys.length) {
        for (let i = 0; i < ord.keys.length; i++) {
          const key: string = ord.keys[i];
          const value: any = ord.values[i];
          switch (key) {
            case "oldpin":
              this.oldpin = value;
              break;
            case "externalConfirmCode":
              this.externalConfirmCode = value;
              break;
            case "operationReferenceId":
              this.operationReferenceId = value;
              break;
          }
        }
      }
    }
  }
}
